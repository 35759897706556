import React, { useState, useEffect } from "react";
import './app3.scss';
import Select from 'react-select';
import logo from '../assets/logo_name-min.png';
import { ethers } from "ethers";
// import { providers } from "ethers";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import ALCHI from "../artifacts/contracts/Token.sol/ALCHI.json"
import LittleAlchemy from "../artifacts/contracts/LittleAlchemy.sol/LittleAlchemy.json"
import Container from 'react-bootstrap/Container';
import Modal from 'react-modal';
// import Farmcontract from "../artifacts/contracts/farm.sol/Farm.json"
// import LP from "../artifacts/contracts/LP.sol/pl.json"

const mintcontract = "0xd5D0C6b5578C179552A5D462c471051F2F87F189"
const tokencontract = "0x1d94cc954fce49db542a61d68901f787b874cf4b"
// const farmcontract = "0xB9d80dbfeAA8f5D673E9c8d06209d15502d36AA0";
// const LPaddress ="0x63179Fb1a3B78b971bA122fC6f302682fC2047Fe";
function App3() {

  const [balanceArray, setBalanceArray] = useState();
  const [account, setAccount] = useState();
  const [accountBalance, setaccountBalance] = useState('...');
  const [isShowModalWallet, setIsShowModalWallet] = useState(false);
  const [msg, setMsg] = useState("...");
  const [isShowModalMsg, setIsShowModalMsg] = useState(false);
  // const [isShowModalFarm, setIsShowModalFarm] = useState(false);
  const [currentWallet, setCurrentWallet] = useState();
  const [chainId, setChainId] = useState();
  const [imgsource, setimgsource] = useState([]);
  const [element, setMintFunction] = useState({ value: 'mintStandard', label: 'Standards Elements' })
  const [NftBanalce, setNftBanalce] = useState([])
  const [mintFee, setmintFee] = useState([])
  const [allow, setAllowance] = useState()
  const [metisBalance, setmetisBalance] = useState(0);
  // const [allowLP, setAllowanceLP] = useState()
  // const [amount, setAmount] = useState('0')
  // const [lpBalnce, setlpBalnce] = useState()
  // const [lpdeposited, setLpDeposited] = useState()
  // const [reward, setReward] = useState('0')
  const imag = ['./imgs/water.png', './imgs/air.png', './imgs/fire.png', './imgs/earth.png', './imgs/steam.png', './imgs/energy.png',
  './imgs/lava.png', './imgs/rain.png', './imgs/mud.png', './imgs/plant.png', './imgs/rock.png', './imgs/sand.png', './imgs/metal.png',
  './imgs/glass.png', './imgs/swamp.png', './imgs/eyeglasse.png', './imgs/electricity.png', './imgs/life.png', './imgs/human.png',
  './imgs/nerd.png', './imgs/computer.png', './imgs/internet.png', './imgs/blockchain.png', './imgs/Bitcoin.png']
  const options = [
    { value: 'mintStandard', label: 'Standards Elements' },
    { value: 'mintPlant', label: 'mint Plant' },
    { value: 'mintSand', label: 'mint Sand' },
    { value: 'mintRock', label: 'mint Rock' },
    { value: 'mintMud', label: 'mint Mud' },
    { value: 'mintRain', label: 'mint Rain' },
    { value: 'mintSteam', label: 'mint Steam' },
    { value: 'mintEnergy', label: 'mint Energy' },
    { value: 'mintLava', label: 'mint Lava' },
    { value: 'mintMetal', label: 'mint Metal' },
    { value: 'mintGlass', label: 'mint Glass' },
    { value: 'mintSwamp', label: 'mint Swamp' },
    { value: 'mintEyeglasse', label: 'mint Eyeglasse' },
    { value: 'mintElectricity', label: 'mint Electricity' },
    { value: 'mintLife', label: 'mint Life' },
    { value: 'mintHuman', label: 'mint Human' },
    { value: 'mintNerd', label: 'mint Nerd' },
    { value: 'mintComputer', label: 'mint Computer' },
    { value: 'mintInternet', label: 'mint Internet' },
    { value: 'mintBlockchain', label: 'mint Blockchain' },
    { value: 'mintBitcoin', label: 'mint Bitcoin' }
  ]

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  
    return () => clearTimeout(timer);
  });
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-02-4`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };
  const [year] = useState(new Date().getFullYear());
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  function isMetaMaskInstalled(){
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  };
  // Connect Wallet
  async function connectWallet() {
    if  (!isMetaMaskInstalled()) {
      alert("you need Metamask to connect");
    } else {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
      let chain = await window.ethereum.on("chainChanged", (chainId) =>
      setChainId(parseInt(chainId.toString(16), 16)));
    setChainId(parseInt(chain.chainId.toString(16), 16))
      setAccount(accounts[0])
      setIsShowModalWallet(false)
      setCurrentWallet('metamask')

    }
  }

  async function accountInfo(){
    if (account) {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      let metisBalance = await provider.getBalance(account)
      metisBalance = ethers.utils.formatEther(metisBalance)

      await window.ethereum.on("chainChanged", (chainId) =>
        setChainId(parseInt(chainId.toString(16), 16))
      );
  
      await window.ethereum.on('accountsChanged', (accounts) =>
        setAccount(accounts[0])
      )
      setmetisBalance(parseFloat(metisBalance).toFixed(2))
      getaccountBalance()
    } else {
      alert('0No wallet is connected')
    }
    }
   
  async function SwitchNetwork(){
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x440' }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{ chainId: '0x440', rpcUrl: 'https://andromeda.metis.io/?owner=1088' /* ... */ }],
          });
        } catch (addError) {
        }
      }
    }
  }
  // Connect MatchWallet
  // async function connectMathWallet() {
  //   try {
  //     const account = await window.harmony.getAccount();
  //     setAccount(account.address)
  //     setIsShowModalWallet(false)
  //     setCurrentWallet('mathwallet')
  //   } catch {
  //     alert('Please login MathWallet')
  //   }
  // }

  // Connect WalletConnect
  // async function connectWalletConnect() {
  //   const walletConnectProvider = new WalletConnectProvider({
  //     infuraId: "PASTE_INFURA_ID_HERE"
  //   });

  //   try {
  //     await walletConnectProvider.enable();
  //     const provider = new providers.Web3Provider(walletConnectProvider);
  //     const signer = provider.getSigner();
  //     setAccount(await signer.getAddress())
  //     setIsShowModalWallet(false)
  //     setCurrentWallet('walletconnect')
  //   } catch {
  //     setIsShowModalWallet(false)
  //   }
  // }
  async function myElements() {
    if (account) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()
      const contract2 = new ethers.Contract(mintcontract, LittleAlchemy.abi, signer)
      const mintFee = await contract2.fee.call();
      setmintFee(parseFloat(ethers.utils.formatEther(mintFee)));
      const ownerAddress = [account, account, account, account, account, account, account, account, account, account, account, account, account, account,
        account, account, account, account, account, account, account, account, account, account]
      const ownerIds = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
      async function getBalance() {
        const balanceArray = await contract2.balanceOfBatch(ownerAddress, ownerIds);
        setBalanceArray(balanceArray);
        if (balanceArray != null) {
          setimgsource([])
          for (let index = 0; index < balanceArray.length; index++) {
            if (balanceArray[index].toString() !== '0') {
              setimgsource(imgsource => [...imgsource, imag[index]])
              setNftBanalce(NftBanalce => [...NftBanalce, balanceArray[index].toString()])
            }
          }
        } else {
          alert('You need to mint your first element')
        }
      }

      getBalance()
    } else {
      alert('No wallet is connected')
    }
  }

  async function getaccountBalance() {
    if (account) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()
      const contract = new ethers.Contract(tokencontract, ALCHI.abi, signer)
      // const lpcontract = new ethers.Contract(LPaddress, LP, signer)
      // const facontract = new ethers.Contract(farmcontract, Farmcontract, signer);
      // const reward = await facontract.pending("0", account);
      // const lpbalance = await lpcontract.balanceOf(account);
      // const lpdeposited = await facontract.deposited("0", account);
      // setLpDeposited(ethers.utils.formatEther(lpdeposited));
      // setlpBalnce(parseFloat(ethers.utils.formatEther(lpbalance)));
      // setReward(parseFloat(ethers.utils.formatEther(reward)));
      const accountBalance = await contract.balanceOf(account);
      setaccountBalance(parseFloat(ethers.utils.formatEther(accountBalance)));
      // allowanceLP();
      myElements();
      allowance();
    } else {
      alert('1No wallet is connected')
    }

  }
  async function allowance() {
    if (account) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()
      const contract = new ethers.Contract(tokencontract, ALCHI.abi, signer)
      const value = await contract.allowance(account, mintcontract)
      const amount = value.toString()
      if (amount === '0') {
        setAllowance(false)
      } else {
        setAllowance(true)
      }
      console.log(imgsource)
    } else {
      alert('No wallet is connected')
    }
  }
  async function approuve() {
    if (account) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()
      const value = '1000000000000000000000'
      const contract = new ethers.Contract(tokencontract, ALCHI.abi, signer)
      const tx = await contract.approve(mintcontract, value)
      console.log(tx)
      setAllowance(true)
    } else {
      alert('No wallet is connected')
    }
  }
  // async function approuveLP() {
  //   console.log(allowLP)
  //   if (account) {
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const signer = provider.getSigner()
  //     const value = '10000000000000000000'
  //     const contract = new ethers.Contract(LPaddress, LP, signer)
  //     const tx = await contract.approve(farmcontract, value)
  //     console.log(tx)
  //     setAllowanceLP(true)
  //   } else {
  //     alert('No wallet is connected')
  //   }
  // }
  // async function allowanceLP() {
  //   if (account) {
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const signer = provider.getSigner();
  //     const contract = new ethers.Contract(LPaddress, LP, signer);
  //     const value = await contract.allowance(account, farmcontract);
  //     const amount = value.toString();
  //     if (amount === "0") {
  //       setAllowanceLP(false);
  //     } else {
  //       setAllowanceLP(true);
  //     }
  //   } else {
  //     alert("No wallet is connected");
  //   }
  // }
  // async function farm( amount) {
  //   if (account) {
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const signer = provider.getSigner();
  //     const contract2 = new ethers.Contract(farmcontract, Farmcontract, signer);
  //     const value = parseFloat(ethers.utils.parseEther(amount))
  //     try {
  //       const addToUsers = await contract2.deposit("0", value.toString());
  //       const messag =
  //         "https://stardust-explorer.metis.io/tx/" + addToUsers.hash.toString();
  //       setMsg(messag);
  //     } catch (error) {
  //       console.log(error); //fires as the contract reverted the payment
  //       if (error.data) {
  //         setMsg(error.data.message.toString());
  //       } else {
  //         setMsg("error ");
  //       }
  //     }
  //     setIsShowModalMsg(true);
  //   } else {
  //     alert("No wallet is connected");
  //   }
  // }
  // async function withdraw( amount) {
  //   if (account) {
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const signer = provider.getSigner();
  //     const contract2 = new ethers.Contract(farmcontract, Farmcontract, signer);
  //     const value = parseFloat(ethers.utils.parseEther(amount))
  //     console.log("lp", value)
  //     try {
  //       const addToUsers = await contract2.withdraw("0", value.toString());
  //       const messag =
  //         "https://stardust-explorer.metis.io/tx/" + addToUsers.hash.toString();
  //       setMsg(messag);
  //     } catch (error) {
  //       console.log(error); //fires as the contract reverted the payment
  //       if (error.data) {
  //         setMsg(error.data.message.toString());
  //       } else {
  //         setMsg("error ");
  //       }
  //     }
  //     setIsShowModalMsg(true);
  //   } else {
  //     alert("No wallet is connected");
  //   }
  // }
  async function Mint(element) {
    if (account) {
      console.log(element);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = new ethers.Contract(mintcontract, LittleAlchemy.abi, signer);
      const mintelement = element.toString();
      try {
        const addToUsers = await contract2[mintelement]();
        const messag = "https://andromeda-explorer.metis.io/tx/" + addToUsers.hash.toString()
        setMsg(messag)
      } catch (error) {
        console.log(error) //fires as the contract reverted the payment
        if (error.LittleAlchemy) {
          setMsg(error.LittleAlchemy.message.toString())
        } else {
          setMsg("you can't mint this element")
        }

      }
      setIsShowModalMsg(true)

    } else {
      alert('No wallet is connected')
    }
  }
  // const changeAmount = ({target}) => {
  //   setAmount(target.value)
  // }
  return (
    <div className="app3">
      <div className="container">
      <div className="header">
        <img src="imgs/logo_name.png" className="logo" alt="" />
        {account ?
        <>
          <label className="remarks_price ">
          {'Balance ' + metisBalance + ' Metis '}
          </label>
          <button className="btn-connect-wallet" onClick={() => setIsShowModalWallet(true)}>
           {account}
          </button>
          </>
          : <button onClick={() => setIsShowModalWallet(true)}>Connect Wallet </button>
        }
      </div>
      <div>
      {timerComponents.length ? 
      <label className="remarks_price ">
      Countdown for Andromeda mainnet deploy
      <br />
      {timerComponents}
      </label>
       : 
        currentWallet
          ? (chainId === 1088
            
            ?
             <Container className="container ">
              <h1>Little Alchemy </h1>
              <div className={`card swap-card`}>
                <label>Rules</label>
                <ul className="un">
                  <li>update your balance</li>
                  <li>You need to have ALCHI token to mint elements</li>
                  <li>you need to have a specific element to mint new specifics elements</li>
                </ul>
                <label className="remarks">Be the first to find the Bitcoin element. Good Luck. </label>
                <label>Netswap link to buy ALCHI</label>
                <a
                  href="https://info.netswap.io/token/0x1d94cc954fce49db542a61d68901f787b874cf4b"
                  rel="noopener"
                > ALCHI
                </a>
                <br />
                <label onClick={() => accountInfo()} className="remarks">click here to update your balance </label>
              </div>
                  <div className="sending-line"></div>
                  <div className={`card card-receive`}>
                    <div>
                      <label>ALCHI Balance</label>
                      <h2>{accountBalance}</h2>
                    </div>
                    <div className="icon-box">
                      <img src={logo} alt="" />
                    </div>
                  </div>
                  {imgsource !== null ?
                    <div className={"card"}>
                    <h3>Minted NFT Elements: </h3>
                    <div className={""}>
                      {
                        imgsource.map((image, index) => {
                          return (
                            <div key={image} className={"gallery"}>
                              <img key={index} src={imgsource[index]} alt="" />
                              <br />
                              <label className="remarksId"> balance : {NftBanalce[index]}</label>
                            </div>
              
                          )
                        })}
                      
                    </div>
                    <label onClick={() => myElements()} className="remarks">click  to refresh your Elements</label>
                  </div>
                  : <div className={"card card-receive2"}>
                    <h3>You dont have any elements  </h3>
                  </div>
                  }
                  <div className="sending-line"></div>
              
              {allow
                ? <div>
                  
                  <div className={`card card-receive`}>
                    <button onClick={() => Mint(element.value)}>Mint New Element</button>
                    <Select
                      value={element}
                      onChange={setMintFunction}
                      options={options}
                    />
                  </div>
                  <label className="remarks">Minting Fee: {mintFee} ALCHI for element</label>
                </div>

                : <div className={`card `}>
                  <button onClick={() => approuve()}>Approuve </button>
                </div>
              }
            
            
            </Container>
          
            
            : <div className="container ">
              <h1>Wrong Network</h1> <br />
              <h2 >Please connect to the appropriate Metis Andromeda mainnet.</h2>
              <button onClick={() => SwitchNetwork()}>Switch to Andromeda mainnet</button>
              <br />
              <br />
            </div>
          )
          : <div className="container ">
           <div className='card'>
           <h1>Please Connect your Wallet</h1>
           </div>
         
          <br />
          </div>
        }
      
      </div>
      
      <Modal
        isOpen={isShowModalWallet}
        ariaHideApp={false}
        onRequestClose={() => setIsShowModalWallet(false)}
      >
        <div className="modal-header">
          <h3>Select wallet to connect</h3>
          <label onClick={() => setIsShowModalWallet(false)}>x</label>
        </div>
        <div className="modal-content">
          <button onClick={connectWallet}>Metamask</button>
          <button onClick={connectWallet} >WalletConnect </button>
        </div>
      </Modal>
      <Modal
        isOpen={isShowModalMsg}
        ariaHideApp={false}
        onRequestClose={() => setIsShowModalMsg(false)}
      >
        <div className="modal-header">
          <h3>Magic message </h3>
          <label onClick={() => setIsShowModalMsg(false)}>x</label>
        </div>
        <br />
        <div className="modal-content"> <a href={msg}> {msg}</a>
        <br />
        </div>
      </Modal>
      </div>
    </div>
  );
}
export default App3;