import React from "react";

function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src="./imgs/conntact.png"
              alt=""
            />
          </div>
          <div className="col-lg-5">
            <div className='card'>
            <h1>Community</h1>
            
              Join little alchemy NFT community: <br />
              <a
                href="https://twitter.com/AlchiMetis"
                rel="noopener"
              >Twitter
              </a>
               <a
                href="https://t.me/AlchimetisNFT"
                rel="noopener"
              >Telegram
              
              </a>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
