import React from "react";
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { Navigation, Footer, Home, App3, About, Contact } from "./components";
function App() {
  return (
    
      <div className="App">
        
    <HashRouter>
          <Navigation />
          <Switch>
            <Route path="/" exact component={() => <Home />} />
            <Route path="/about" exact component={() => <About />} />
            <Route path="/App3" exact component={() => <App3 />} />
            <Route path="/contact" exact component={() => <Contact />} />
          </Switch>
        
    </HashRouter>
        <Footer />
      </div>
  );
}

export default App;
