import React from "react";
import './app3.scss';

function Footer() {
  return (
    <div className="footer">
      <div className="container">
      <footer className="card">
        
          <p className="m-0 text-center text-white">
            Copyright &copy; ALCHIMETIS 2022
          </p>
        
      </footer>
      </div>
    </div>
  );
}

export default Footer;
