import React from "react";
import { Link, withRouter } from "react-router-dom";

function Navigation(props) {
  return (
    
      <div className="container ">
      <nav className="navbar card navbar-expand ">
          <div>
            <h1>ALCHIMETIS</h1>
          </div>
            <ul className="navbar-nav ">
              <li
                className={`nav-item  ${
                  props.location.pathname === "/" ? "active" : ""
                }`}
              >
                <Link className="nav-link" to="/">
                  Home
                  <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li
                className={`nav-item  ${
                  props.location.pathname === "/about" ? "active" : ""
                }`}
              >
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li
                className={`nav-item  ${
                  props.location.pathname === "/App3" ? "active" : ""
                }`}
              >
                <Link className="nav-link" to="/App3">
                  Launch App
                </Link>
              </li>
              
              <li
                className={`nav-item  ${
                  props.location.pathname === "/contact" ? "active" : ""
                }`}
              >
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
        
      </nav>
      </div>
  );
}

export default withRouter(Navigation);
