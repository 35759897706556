import React from "react";
import './About.scss';

function About() {
  return (
    <div className="about">
      <div className="container">
          
          <div className="card">
            <h1 >About</h1>
            <div className="sending-line"></div>
            <div className="card-receive">
              <div className="container">
              The NFT space is growing faster and today we bring the Little Alchemy Game into the NFT space.
               To play the game, first, you need to buy some ALCHI tokens from the marketplace BUY LINK HERE,
                now you can mint and collect your elements. 
                Example ENERGY= FIRE + AIR
              All fees will be collected at the Treasury address.
              </div>
            
              </div>
          </div>
          <div className="card">
            <h1>Token </h1>
            <div className="sending-line"></div>
            <div className="card-receive">
            <ul className="un">
              <li>Total supply : 44000ALCHI</li>
              <li>Liquidity pool: 8800ALCHI </li>
              <li>Ecosystem rewards: 11000ALCHI</li>
              <li>Reward and community bounty: 10000ALCHI </li>
              <li>Development and team : 4500ALCHI </li>
              <li>Treasury address: 19700ALCHI</li>
            </ul>
            </div>
          </div>
          <div className="card">
              <h1 >Roadmap</h1>
              <div className="sending-line"></div>
                <div className="card-receive">
                <ul className="un">
                <li>Token launch on Netswap platform </li>
                <li>Logo contest</li>
                <li>Website launch and minting starts</li>
                <li>Liquidity pool farming and rewards programs</li>
                <li>Game updates "add more new NFT elements"</li>
                <li>NFT marketplace</li>
                <li>Listing on new DEXs </li>
                <li>New partnerships</li>
                <li>Listing on CEX</li>
                </ul>
                </div>
                
              </div>
        </div>
    </div>
  );
}

export default About;
