import React from "react";
import './app3.scss';
import background from "../assets/background1-min.png"

function Home() {
  return (
    <div className="home">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src={background}
              alt=""
            />
          </div>
          <div className="col-lg-5">
            <h1>Little Alchemy NFT Game</h1>
            <div className='card'>
            <h2>The Game</h2>
              The project is inspired by the famous game "Little Alchemy", which we build for the crypto community the little Alchemy NFT game.
              Everyone can play the game, But to start you need to mint the 4 standards element "fire" " water" "earth" "Air".
              Be the first to Mint the Standards elements and use them to create new ones.
              The last element to be minted in a cycle is the Bitcoin element.
              The Game is a race between players, everyone wants to be the first to mint the Bitcoin element because every time a  bitcoin element has minted the fee for minting doubles.
              <br />
              The players need to have ALCHI token to play, for every mint the players need to pay 0.1 ALCHI to mint new elements 
              <br />
              <br />
               <h2>NFT Marketplace </h2>
              <br />
              We will build a marketplace where you can buy and sell your new created elements 
              <br />
              <br />
               <h2>PL farming </h2>
              <br />
              You can provide liquidity in the Netswap platform METIS/ALCHI, Then you can use the LP tokens you get to farm  Alchi tokens and earn more.
            
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
